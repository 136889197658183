<template>
  <div>
    <div class="min-vh-100">
      <CRow class="w-100 no-gutters">
        <CCol cols="6">
          <h1 class="mr-sm-4 header">NOTIFICATION POINT</h1>
        </CCol>
        <CCol cols="6" class="text-right">
          <b-dropdown id="dropdown-1" class="mr-2 btn-mains small-dropdown medium-dd" right no-flip>
            <template v-slot:button-content>
              <font-awesome-icon icon="ellipsis-v" title="filter-btn" class="text-white d-sm-none" />
              <span class="d-none d-sm-inline">ACTION</span>
            </template>
            <b-dropdown-item @click="exportData(1)">Export Excel</b-dropdown-item>
            <b-dropdown-item @click="exportData(2)">Export CSV</b-dropdown-item>
          </b-dropdown>

          <b-button v-b-toggle.sidebar-1 class="btn-filter mr-0 mr-sm-1">
            <font-awesome-icon icon="filter" title="filter-btn" class="text-white mr-0 mr-sm-1" />
            <span class="d-none d-sm-inline">FILTER</span>
          </b-button>
        </CCol>
      </CRow>
      <b-sidebar
        id="sidebar-1"
        title="FILTER"
        backdrop
        shadow
        backdrop-variant="dark"
        right
        ref="filterSidebar"
      >
        <div class="px-3 py-2">
          <!-- <div class="text-right">
            <button type="button" class="btn btn-link px-0" @click="clearFilter">Clear</button>
          </div>-->
          <div class="row mt-2">
            <div class="col-6">
              <p class="font-weight-bold my-2">Start Date</p>
              <datetime
                placeholder="Please select date"
                class="date-filter"
                v-model="filter.startDate"
                format="dd MMM yyyy"
              ></datetime>
            </div>
            <div class="col-6">
              <p class="font-weight-bold my-2">End Date</p>
              <datetime
                placeholder="Please select date"
                class="date-filter"
                v-model="filter.endDate"
                format="dd MMM yyyy"
              ></datetime>
            </div>
          </div>

          <div class="text-center mt-3">
            <button type="button" class="btn btn-main button" @click="getDataByStatus">Submit</button>
          </div>
        </div>
      </b-sidebar>
      <div class="bg-white-border px-4 px-sm-5 mt-3 py-4">
        <b-row>
          <b-col>
            <b-table
              responsive
              class="text-center"
              striped
              :fields="fields"
              :items="items"
              :busy="isBusy"
              show-empty
              empty-text="No matching records found"
            >
              <template v-slot:cell(customerName)="data">
                <div v-if="data.item.customerName == null">- -</div>
                <div v-else>{{data.item.customerName}}</div>
              </template>
              <template v-slot:cell(expireDate)="data">
                <div v-if="data.item.expireDate == null">-</div>
                <div v-else>
                  <span>
                    {{
                    data.item.expireDate | moment($formatDate)
                    }}
                  </span>
                </div>
              </template>
              <template v-slot:cell(notificationSendDate)="data">
                <div v-if="data.item.notificationSendDate == null">-</div>
                <div v-else>
                  <span>
                    {{
                    data.item.notificationSendDate | moment($formatDate)
                    }}
                  </span>
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="form-inline justify-content-center justify-content-sm-between">
            <div class="d-sm-flex mt-2">
              <b-pagination
                v-model="filter.page"
                :total-rows="rows"
                :per-page="filter.pageSize"
                class="m-md-0"
                @change="pagination"
                align="center"
              ></b-pagination>

              <div class="ml-2">
                <p class="total-record-paging text-nowrap text-center">{{totalRowMessage}}</p>
              </div>
            </div>

            <b-form-select
              v-model="filter.pageSize"
              @change="hanndleChangePerpage"
              :options="pageOptions"
            ></b-form-select>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import * as moment from "moment/moment";

export default {
  name: "NotificationPoint",
  data() {
    return {
      statusList: [],
      modalMessage: "",
      requestDeleteUser: {
        userId: null,
      },
      fields: [
        {
          key: "IDCard",
          label: "ID Card",
          class: "w-100px text-nowrap",
        },
        {
          key: "customerName",
          label: "Customer Name",
          class: "w-100px text-nowrap",
        },
        {
          key: "lineName",
          label: "Line Name",
          class: "w-100px text-nowrap",
        },
        {
          key: "MobileNo",
          label: "Mobile No.",
          class: "w-100px text-nowrap",
        },
        {
          key: "contractNo",
          label: "Contract No.",
          class: "w-100px text-nowrap",
        },
        {
          key: "sklPoint",
          label: "Point",
          class: "w-100px text-nowrap",
        },
        {
          key: "expireDate",
          label: "Expire Date",
        },
        {
          key: "notificationSendDate",
          label: "Notification Send Date",
        },
      ],
      items: [],
      isBusy: false,
      rows: 0,
      filter: {
        startDate: null,
        endDate: null,
        search: "",
        page: 1,
        pageSize: 10,
      },
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      totalRowMessage: "",
    };
  },
  created: async function () {
    this.$isLoading = true;
    await this.getList();
    this.$isLoading = false;
  },
  methods: {
    getList: async function () {
      this.isBusy = true;
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/customer/report/notiPoint`,
        null,
        this.$headers,
        this.filter
      );
      if (resData.result == 1) {
        this.items = resData.detail.data;
        this.rows = resData.detail.totalData;
        if (resData.detail.totalData > 0) {
          let numRecordFirst = this.filter.page * this.filter.pageSize;
          numRecordFirst = numRecordFirst - this.filter.pageSize + 1;
          let numRecordLast =
            this.filter.page * this.filter.pageSize > resData.detail.totalData
              ? resData.detail.totalData
              : this.filter.page * this.filter.pageSize;
          this.totalRowMessage =
            "Showing " +
            numRecordFirst +
            " - " +
            numRecordLast +
            " of " +
            resData.detail.totalData +
            " entries";
        }

        this.isBusy = false;
      }
    },
    pagination(Page) {
      this.filter.page = Page;
      this.getList();
    },
    hanndleChangePerpage(value) {
      this.filter.page = 1;
      this.filter.pageSize = value;
      this.getList();
    },
    handleSearch(e) {
      if (e.keyCode === 13) {
        this.filter.page = 1;
        this.getList();
      }
    },
    btnSearch() {
      this.filter.page = 1;
      this.getList();
    },
    getDataByStatus() {
      this.$refs.filterSidebar.hide();
      this.getList();
    },
    exportData: async function (type) {
      // this.modalAlertShow = true;
      // this.imgModal = "/img/loading.svg";
      // this.msgModal = "In progress. Exporting Data...";
      // this.isSuccess = true;
      // this.hideClose = true;
      this.filter.fileType = type;

      axios({
        url: `${this.$baseUrl}/api/customer/export/notiPoint`,
        method: "post",
        headers: this.$headers,
        responseType: "blob",
        data: this.filter,
      })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          var dateExcel = moment().format("DDMMYYYYhhmmss");

          var typeName = "";
          if (type == 1) typeName = ".xlsx";
          else typeName = ".csv";

          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            `Notification-Point-` + dateExcel + typeName
          );
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((error) => {
          if (error.response.status === 500) {
          }
        });
    },
  },
};
</script>       

<style scoped>
</style>